@function px2rem($px) {
    $rem: 1440px;
    @return ($px/$rem)+rem;
}

textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: rgb(206, 203, 203);
}

textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgb(206, 203, 203);
}

textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgb(206, 203, 203);
}

textarea::-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: rgb(206, 203, 203);
}

/*  */
input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: rgb(206, 203, 203);
}

input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgb(206, 203, 203);
}

input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgb(206, 203, 203);
}

input::-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: rgb(206, 203, 203);
}


.clearfix:after {
    content   : ' ';
    height    : 0;
    display   : block;
    clear     : both;
    visibility: hidden;
}

.clearfix {
    zoom: 1;
}

html {
    color: #222;
}

* {
    padding   : 0;
    margin    : 0;
    font-size : px2rem(16px);
    box-sizing: content-box;
}

*::-moz-selection {
    background-color: #b3d4fc;
    text-shadow     : none;
}

*::selection {
    background-color: #b3d4fc;
    text-shadow     : none;
}

li {
    list-style-type: none;
}

.block-center {
    display: block;
    margin : 0 auto;
}

/* 导航栏样式 */
nav {
    background: rgba(255, 255, 255, 1);
    position: fixed;
    width: 100%;
    z-index: 100;
    .logo {
        width        : px2rem(136px);
        float        : left;
        height       : px2rem(40px);
        margin-top   : px2rem(30px);
        margin-left  : px2rem(120px);
        margin-bottom: px2rem(30px);
    }


    ul {
        margin-left: px2rem(766px);
        font-size  : px2rem(18px);
        height     : px2rem(100px);

        img {
            display      : inline-block;
            width        : px2rem(22px);
            margin-left  : px2rem(21px);
            margin-top   : px2rem(13px);
            margin-bottom: px2rem(13px);
        }

        li {
            display    : inline-block;
            margin     : px2rem(26px) 0rem;
            line-height: px2rem(48px);
            font-size  : px2rem(18px);
            width      : px2rem(146px);
            height     : px2rem(48px);
            cursor     : pointer;
            span {
                float: left;
            }
        }

        li:hover {
            background   : linear-gradient(270deg, rgba(3, 136, 215, 1) 0%, rgba(28, 61, 150, 1) 100%);
            border-radius: px2rem(24px);
            color        : #FFFFFF;
        }
    }
}


// /* 广告部分样式 */

.ads {
    background-image   : url(../img/img_banner.png);
    background-size    : cover;
    height             : px2rem(700px);
    background-repeat  : no-repeat;
    background-position: top;
}

/* 关于部分的样式 */
.about {
    display         : block;
    height          : px2rem(700px);
    position        : relative;
    background-color: #f6f7f9;

    div h3 {
        display    : block;
        font-size  : px2rem(26px);
        margin-top : px2rem(49px);
        margin-left: px2rem(40px);
    }

    img {
        display : block;
        position: absolute;
        width   : px2rem(697px);
        height  : px2rem(489px);
        top     : px2rem(106px);
        left    : px2rem(623px);
    }

    div {
        position        : absolute;
        background-color: rgb(255, 0, 0);
        height          : px2rem(400px);
        width           : px2rem(693px);
        z-index         : 99;
        background      : #ffffff;
        opacity         : 0.9;
        top             : px2rem(150px);
        left            : px2rem(120px);
    }

    .chinese {
        font-size    : px2rem(16px);
        padding-left : px2rem(40px);
        padding-right: px2rem(62px);
        margin-top   : px2rem(67px);
        font-family  : PingFangSC-Regular, PingFang SC;
        font-weight  : 400;
        color        : rgba(51, 51, 51, 1);
        line-height  : px2rem(31px);
    }

    .english {
        font-family  : PingFangSC-Regular, PingFang SC;
        font-size    : px2rem(16px);
        padding-left : px2rem(40px);
        padding-right: px2rem(62px);
        margin-top   : px2rem(20px);
        font-weight  : 400;
        color        : rgba(153, 153, 153, 1);
        line-height  : px2rem(31px);
    }
}


.developer {
    background-image : url(../img/img_kaifazhe.png);
    height           : px2rem(350px);
    width            : 100%;
    background-repeat: no-repeat;
    background-size  : cover;
}

.concept {
    background-image : url(../img/img_banner_lilian.png);
    background-repeat: no-repeat;
    background-size  : cover;
    height           : px2rem(583px);
    width            : 100%;
    text-align       : center;
    font-size        : px2rem(16px);

    p {
        font-size: px2rem(16px);
    }

    img {
        display   : inline-block;
        margin-top: px2rem(83px);
        width     : px2rem(25px);
        height    : px2rem(25px);
    }

    p:first-of-type {
        display      : block;
        margin-top   : px2rem(10px);
        font-size    : px2rem(37px);
        margin-bottom: px2rem(50px);
    }

    p:nth-child(4) {
        display   : inline-block;
        font-size : px2rem(14px);
        color     : rgba(51, 51, 51, 1);
        text-align: center;
        padding   : px2rem(12px) px2rem(66px);
        border    : px2rem(1px) solid gray;
        margin-top: px2rem(60px);
    }

    div {
        text-align: center;
    }

    div div {
        float     : left;
        height    : px2rem(50px);
        width     : px2rem(191px);
        text-align: center;
        margin-top: px2rem(36px);

        img {
            display: block;
            height : px2rem(46px);
            width  : px2rem(46px);
            margin : 0 auto;
            padding: 0;
        }

        .icon-des {
            width: px2rem(764px);
        }
    }

    .first-key {
        margin-left: px2rem(314px);
    }
}

.advantage {
    background-image : url(../img/ic_banner_bg.png);
    height           : px2rem(728px);
    width            : 100%;
    background-size  : cover;
    background-repeat: no-repeat;
    text-align       : center;
    position         : relative;


    p:first-of-type {
        color      : white;
        font-size  : px2rem(26px);
        line-height: px2rem(37px);
        padding-top: px2rem(52px);
    }

    p:first-of-type+p {
        color      : white;
        font-size  : px2rem(16px);
        padding-top: px2rem(20px);
    }

    img {
        display : block;
        position: absolute;
        top     : px2rem(167px);
        left    : px2rem(120px);
        width   : px2rem(1200px);
        height  : px2rem(380px);

        &+p {
            color     : #FFFFFF;
            margin-top: px2rem(445px);
            font-size : px2rem(16px);
        }
    }

}

.our-advantage {
    text-align : center;
    height     : px2rem(603px);
    padding-top: px2rem(60px);
    background : rgba(246, 247, 249, 1);


    &>p {
        display       : inline-block;
        font-size     : px2rem(26px);
        padding-bottom: px2rem(21px);
    }

    div div {
        display   : block;
        float     : left;
        width     : px2rem(296px);
        // height : px2rem(380px);
        font-size : 0;

        &:first-of-type {
            margin-left: px2rem(120px);
        }

        margin-right: px2rem(5px);

        img {
            width : px2rem(296px);
            height: px2rem(380px);
        }

        p {
            text-align      : left;
            font-size       : px2rem(16px);
            font-family     : PingFangSC-Regular, PingFang SC;
            font-weight     : 400;
            padding         : px2rem(20px) px2rem(19px);
            background-color: white;
        }
    }
}

.client {
    text-align: center;
    font-size : px2rem(26px);
    color     : #363636;
    height    : px2rem(503px);
    width     : 100%;
    background: rgba(246, 247, 249, 1);

    p {
        display      : inline-block;
        margin-top   : px2rem(60px);
        margin-bottom: px2rem(60px);
        font-size    : px2rem(26px);
        color        : #333333;
    }

    &>div {
        display              : grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows   : repeat(2, 1r);
        grid-gap             : px2rem(30px) px2rem(93px);
        padding              : 0 px2rem(120px);


        img {
            width     : px2rem(90px);
            height    : px2rem(90px);
            margin-top: px2rem(15px);
        }

        div {
            border-radius   : px2rem(24px);
            width           : px2rem(230px);
            height          : px2rem(120px);
            background-color: white;
        }
    }
}

.contacts {
    box-sizing      : border-box;
    background-color: black;
    height          : px2rem(1164px);
    width           : 100%;
    text-align      : center;
    padding         : 0px px2rem(120px);

    &>p {
        font-size : px2rem(26px);
        color     : #FFFFFF;
        display   : inline-block;
        margin-top: px2rem(53px);
    }

    img {
        float     : left;
        display   : block;
        width     : px2rem(585px);
        height    : px2rem(770px);
        margin-top: px2rem(56px);
    }

    div {
        float     : left;
        margin-top: px2rem(56px);
    }

    form {
        color       : white;
        padding-left: px2rem(31px);

        fieldset {
            border: none;
            height: px2rem(766px);
        }

        input {
            display      : block;
            height       : px2rem(18px);
            width        : px2rem(513px);
            font-size    : px2rem(16px);
            padding      : px2rem(20px);
            background   : rgba(96, 103, 115, 1);
            color        : rgb(206, 203, 203);
            line-height  : px2rem(22px);
            border-radius: px2rem(10px);
            border       : none;
        }

        textarea {
            resize       : none;
            height       : px2rem(108px);
            width        : px2rem(513px);
            margin       : 0;
            background   : rgba(96, 103, 115, 1);
            color        : rgb(206, 203, 203);
            font-family  : PingFangSC-Regular, PingFang SC;
            line-height  : px2rem(22px);
            padding      : px2rem(20px);
            border-radius: px2rem(10px);
            border       : none;
            font-size    : px2rem(16px);
            font-weight  : 400;
        }

        input {
            margin-bottom: px2rem(30px);
        }

        input[type='submit'] {
            display      : block;
            height       : px2rem(18px);
            width        : px2rem(513px);
            font-size    : px2rem(16px);
            padding      : px2rem(20px);
            margin       : 0;
            margin-top   : px2rem(30px);
            background   : rgba(0, 145, 255, 1);
            border       : none;
            border-radius: px2rem(10px);
            color        : rgb(206, 203, 203);
            cursor       : pointer;
        }
    }

    div {
        &.social {
            width     : px2rem(1170px);
            margin-top: px2rem(30px);

            img {
                margin: 0;
                width : px2rem(40px);
                height: px2rem(40px);
            }

            .qrcode {
                margin : 0;
                padding: 0;
                float  : right;
                width  : px2rem(207px);

                img {

                    width : px2rem(207px);
                    height: px2rem(207px);
                }
            }

            div:first-child {
                height    : px2rem(90px);
                margin-top: px2rem(89px);
                float     : left;

                p {
                    display   : block;
                    font-size : px2rem(14px);
                    margin-top: px2rem(70px);
                    color     : #969292;

                    a {
                        text-decoration: none;
                    }

                    a:link,
                    a:visited,
                    a:hover,
                    a:active {
                        color: #0091FF;
                    }
                }

                img {
                    display     : inline-block;
                    margin-right: px2rem(30px);
                    border      : px2rem(1px) dashed #969292;
                }
            }
        }
    }
}