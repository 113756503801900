@charset "UTF-8";
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cecbcb; }

textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cecbcb; }

textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cecbcb; }

textarea::-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cecbcb; }

/*  */
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cecbcb; }

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cecbcb; }

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cecbcb; }

input::-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cecbcb; }

.clearfix:after {
  content: ' ';
  height: 0;
  display: block;
  clear: both;
  visibility: hidden; }

.clearfix {
  zoom: 1; }

html {
  color: #222; }

* {
  padding: 0;
  margin: 0;
  font-size: 0.01111rem;
  box-sizing: content-box; }

*::-moz-selection {
  background-color: #b3d4fc;
  text-shadow: none; }

*::selection {
  background-color: #b3d4fc;
  text-shadow: none; }

li {
  list-style-type: none; }

.block-center {
  display: block;
  margin: 0 auto; }

/* 导航栏样式 */
nav {
  background: white;
  position: fixed;
  width: 100%;
  z-index: 100; }
  nav .logo {
    width: 0.09444rem;
    float: left;
    height: 0.02778rem;
    margin-top: 0.02083rem;
    margin-left: 0.08333rem;
    margin-bottom: 0.02083rem; }
  nav ul {
    margin-left: 0.53194rem;
    font-size: 0.0125rem;
    height: 0.06944rem; }
    nav ul img {
      display: inline-block;
      width: 0.01528rem;
      margin-left: 0.01458rem;
      margin-top: 0.00903rem;
      margin-bottom: 0.00903rem; }
    nav ul li {
      display: inline-block;
      margin: 0.01806rem 0rem;
      line-height: 0.03333rem;
      font-size: 0.0125rem;
      width: 0.10139rem;
      height: 0.03333rem;
      cursor: pointer; }
      nav ul li span {
        float: left; }
    nav ul li:hover {
      background: linear-gradient(270deg, #0388d7 0%, #1c3d96 100%);
      border-radius: 0.01667rem;
      color: #FFFFFF; }

.ads {
  background-image: url(../img/img_banner.png);
  background-size: cover;
  height: 0.48611rem;
  background-repeat: no-repeat;
  background-position: top; }

/* 关于部分的样式 */
.about {
  display: block;
  height: 0.48611rem;
  position: relative;
  background-color: #f6f7f9; }
  .about div h3 {
    display: block;
    font-size: 0.01806rem;
    margin-top: 0.03403rem;
    margin-left: 0.02778rem; }
  .about img {
    display: block;
    position: absolute;
    width: 0.48403rem;
    height: 0.33958rem;
    top: 0.07361rem;
    left: 0.43264rem; }
  .about div {
    position: absolute;
    background-color: red;
    height: 0.27778rem;
    width: 0.48125rem;
    z-index: 99;
    background: #ffffff;
    opacity: 0.9;
    top: 0.10417rem;
    left: 0.08333rem; }
  .about .chinese {
    font-size: 0.01111rem;
    padding-left: 0.02778rem;
    padding-right: 0.04306rem;
    margin-top: 0.04653rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.02153rem; }
  .about .english {
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 0.01111rem;
    padding-left: 0.02778rem;
    padding-right: 0.04306rem;
    margin-top: 0.01389rem;
    font-weight: 400;
    color: #999999;
    line-height: 0.02153rem; }

.developer {
  background-image: url(../img/img_kaifazhe.png);
  height: 0.24306rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover; }

.concept {
  background-image: url(../img/img_banner_lilian.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 0.40486rem;
  width: 100%;
  text-align: center;
  font-size: 0.01111rem; }
  .concept p {
    font-size: 0.01111rem; }
  .concept img {
    display: inline-block;
    margin-top: 0.05764rem;
    width: 0.01736rem;
    height: 0.01736rem; }
  .concept p:first-of-type {
    display: block;
    margin-top: 0.00694rem;
    font-size: 0.02569rem;
    margin-bottom: 0.03472rem; }
  .concept p:nth-child(4) {
    display: inline-block;
    font-size: 0.00972rem;
    color: #333333;
    text-align: center;
    padding: 0.00833rem 0.04583rem;
    border: 0.00069rem solid gray;
    margin-top: 0.04167rem; }
  .concept div {
    text-align: center; }
  .concept div div {
    float: left;
    height: 0.03472rem;
    width: 0.13264rem;
    text-align: center;
    margin-top: 0.025rem; }
    .concept div div img {
      display: block;
      height: 0.03194rem;
      width: 0.03194rem;
      margin: 0 auto;
      padding: 0; }
    .concept div div .icon-des {
      width: 0.53056rem; }
  .concept .first-key {
    margin-left: 0.21806rem; }

.advantage {
  background-image: url(../img/ic_banner_bg.png);
  height: 0.50556rem;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  position: relative; }
  .advantage p:first-of-type {
    color: white;
    font-size: 0.01806rem;
    line-height: 0.02569rem;
    padding-top: 0.03611rem; }
  .advantage p:first-of-type + p {
    color: white;
    font-size: 0.01111rem;
    padding-top: 0.01389rem; }
  .advantage img {
    display: block;
    position: absolute;
    top: 0.11597rem;
    left: 0.08333rem;
    width: 0.83333rem;
    height: 0.26389rem; }
    .advantage img + p {
      color: #FFFFFF;
      margin-top: 0.30903rem;
      font-size: 0.01111rem; }

.our-advantage {
  text-align: center;
  height: 0.41875rem;
  padding-top: 0.04167rem;
  background: #f6f7f9; }
  .our-advantage > p {
    display: inline-block;
    font-size: 0.01806rem;
    padding-bottom: 0.01458rem; }
  .our-advantage div div {
    display: block;
    float: left;
    width: 0.20556rem;
    font-size: 0;
    margin-right: 0.00347rem; }
    .our-advantage div div:first-of-type {
      margin-left: 0.08333rem; }
    .our-advantage div div img {
      width: 0.20556rem;
      height: 0.26389rem; }
    .our-advantage div div p {
      text-align: left;
      font-size: 0.01111rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      padding: 0.01389rem 0.01319rem;
      background-color: white; }

.client {
  text-align: center;
  font-size: 0.01806rem;
  color: #363636;
  height: 0.34931rem;
  width: 100%;
  background: #f6f7f9; }
  .client p {
    display: inline-block;
    margin-top: 0.04167rem;
    margin-bottom: 0.04167rem;
    font-size: 0.01806rem;
    color: #333333; }
  .client > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1r);
    grid-gap: 0.02083rem 0.06458rem;
    padding: 0 0.08333rem; }
    .client > div img {
      width: 0.0625rem;
      height: 0.0625rem;
      margin-top: 0.01042rem; }
    .client > div div {
      border-radius: 0.01667rem;
      width: 0.15972rem;
      height: 0.08333rem;
      background-color: white; }

.contacts {
  box-sizing: border-box;
  background-color: black;
  height: 0.80833rem;
  width: 100%;
  text-align: center;
  padding: 0px 0.08333rem; }
  .contacts > p {
    font-size: 0.01806rem;
    color: #FFFFFF;
    display: inline-block;
    margin-top: 0.03681rem; }
  .contacts img {
    float: left;
    display: block;
    width: 0.40625rem;
    height: 0.53472rem;
    margin-top: 0.03889rem; }
  .contacts div {
    float: left;
    margin-top: 0.03889rem; }
  .contacts form {
    color: white;
    padding-left: 0.02153rem; }
    .contacts form fieldset {
      border: none;
      height: 0.53194rem; }
    .contacts form input {
      display: block;
      height: 0.0125rem;
      width: 0.35625rem;
      font-size: 0.01111rem;
      padding: 0.01389rem;
      background: #606773;
      color: #cecbcb;
      line-height: 0.01528rem;
      border-radius: 0.00694rem;
      border: none; }
    .contacts form textarea {
      resize: none;
      height: 0.075rem;
      width: 0.35625rem;
      margin: 0;
      background: #606773;
      color: #cecbcb;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 0.01528rem;
      padding: 0.01389rem;
      border-radius: 0.00694rem;
      border: none;
      font-size: 0.01111rem;
      font-weight: 400; }
    .contacts form input {
      margin-bottom: 0.02083rem; }
    .contacts form input[type='submit'] {
      display: block;
      height: 0.0125rem;
      width: 0.35625rem;
      font-size: 0.01111rem;
      padding: 0.01389rem;
      margin: 0;
      margin-top: 0.02083rem;
      background: #0091ff;
      border: none;
      border-radius: 0.00694rem;
      color: #cecbcb;
      cursor: pointer; }
  .contacts div.social {
    width: 0.8125rem;
    margin-top: 0.02083rem; }
    .contacts div.social img {
      margin: 0;
      width: 0.02778rem;
      height: 0.02778rem; }
    .contacts div.social .qrcode {
      margin: 0;
      padding: 0;
      float: right;
      width: 0.14375rem; }
      .contacts div.social .qrcode img {
        width: 0.14375rem;
        height: 0.14375rem; }
    .contacts div.social div:first-child {
      height: 0.0625rem;
      margin-top: 0.06181rem;
      float: left; }
      .contacts div.social div:first-child p {
        display: block;
        font-size: 0.00972rem;
        margin-top: 0.04861rem;
        color: #969292; }
        .contacts div.social div:first-child p a {
          text-decoration: none; }
        .contacts div.social div:first-child p a:link,
        .contacts div.social div:first-child p a:visited,
        .contacts div.social div:first-child p a:hover,
        .contacts div.social div:first-child p a:active {
          color: #0091FF; }
      .contacts div.social div:first-child img {
        display: inline-block;
        margin-right: 0.02083rem;
        border: 0.00069rem dashed #969292; }
